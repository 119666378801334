import { Proto } from "../../../pojo/Proto";
import ServiceStatus = Proto.ServiceStatus;
import { S25Util } from "../../../util/s25-util";
import { SpaceService } from "../../../services/space.service";
import { ResourceService } from "../../../services/resource.service";
import { S25Profile } from "../ProfileI";
import { ReservationService } from "../../../services/reservation.service";
import { ExpandedInfoI, S25Event } from "../EventMicroI";
import { Event } from "../../../pojo/Event";
import { S25Reservation, S25ObjectReservation, S25RsReservation, S25RmReservation, ObjectType } from "../ReservationI";
import { S25RmReservationUtil } from "./s25.rm.reservation";

import Approval = Event.Workflow.Task;

function wsConvertState(reservation_state: 99 | 1): S25Reservation["state"] {
    return reservation_state === 1 ? "active" : "cancelled";
}
export class S25ReservationUtil {
    public static setFromWS(profile: S25Profile, expandedInfo: ExpandedInfoI, approval?: Approval): S25Reservation[] {
        let wsRsrv = profile.reservations;
        wsRsrv = wsRsrv.sort(
            (a: any, b: any) => (S25Util.date.parse(a.evStartDt) as any) - (S25Util.date.parse(b.evStartDt) as any),
        ); // data order by rsrvId

        let S25Rsrvs: S25Reservation[] = [];

        wsRsrv.forEach((r: any) => {
            let locations: S25ObjectReservation[] = [];
            let resources: S25ObjectReservation[] = [];
            let reserved: S25RmReservation[] | S25RsReservation[] = [];
            let draft: S25RmReservation[] | S25RsReservation[] = [];
            let requested: S25RmReservation[] | S25RsReservation[] = [];

            if (r.spaces) {
                if (r.spaces[0].reserved) reserved = this.setLocations(r.spaces[0].reserved, expandedInfo.spaces);
                if (r.spaces[0].draft) draft = this.setLocations(r.spaces[0].draft, expandedInfo.spaces);
                if (r.spaces[0].requested) requested = this.setLocations(r.spaces[0].requested, expandedInfo.spaces);
                locations.push({
                    requested: requested,
                    draft: draft,
                    reserved: reserved,
                });
            }

            if (r.resources) {
                if (r.resources[0].reserved)
                    reserved = this.setResources(r.resources[0].reserved, expandedInfo.resources) as S25RmReservation[];
                if (r.resources[0].draft)
                    draft = this.setResources(r.resources[0].draft, expandedInfo.resources) as S25RmReservation[];
                if (r.resources[0].requested)
                    requested = this.setResources(
                        r.resources[0].requested,
                        expandedInfo.resources,
                    ) as S25RmReservation[];
                resources.push({
                    requested: requested,
                    draft: draft,
                    reserved: reserved,
                });
            }

            let addTime = profile.occurrenceDefn.addedTime;
            let preEventStart: Date;
            let postEventEnd: Date;
            let setupStart: Date;
            let takeDownEnd: Date;

            if (addTime?.preEvent) {
                preEventStart = S25Util.date.parse(
                    S25Util.date.addMinutes(r.evStartDt, -1 * S25Util.ISODurationToMinutes(addTime.preEvent)),
                );
            }

            if (addTime?.postEvent) {
                postEventEnd = S25Util.date.parse(
                    S25Util.date.addMinutes(r.evEndDt, S25Util.ISODurationToMinutes(addTime.postEvent)),
                );
            }

            if (addTime?.setup) {
                if (preEventStart) {
                    setupStart = S25Util.date.parse(
                        S25Util.date.addMinutes(preEventStart, -1 * S25Util.ISODurationToMinutes(addTime.setup)),
                    );
                } else {
                    setupStart = S25Util.date.parse(
                        S25Util.date.addMinutes(r.evStartDt, -1 * S25Util.ISODurationToMinutes(addTime.setup)),
                    );
                }
            }

            if (addTime?.takedown) {
                if (postEventEnd) {
                    takeDownEnd = S25Util.date.parse(
                        S25Util.date.addMinutes(postEventEnd, S25Util.ISODurationToMinutes(addTime.takedown)),
                    );
                } else {
                    takeDownEnd = S25Util.date.parse(
                        S25Util.date.addMinutes(r.evEndDt, S25Util.ISODurationToMinutes(addTime.takedown)),
                    );
                }
            }

            S25Rsrvs.push({
                itemId: r.rsrvId,
                locations: locations as S25RmReservation[],
                resources: resources as S25RsReservation[],
                comment: r?.comments || "",
                state: wsConvertState(r.state),
                eventStart: S25Util.date.parse(r.evStartDt),
                eventEnd: S25Util.date.parse(r.evEndDt),
                preEventStart: preEventStart,
                postEventEnd: postEventEnd,
                setupStart: setupStart,
                takeDownEnd: takeDownEnd,
                profileId: profile.profileId, // for seperate view
                profileName: profile.name, // for seperate view
            } as S25Reservation);
        });
        return S25Rsrvs;
    }

    public static setState(rsrv: S25Reservation, state: S25Reservation["state"]) {
        rsrv.state = state;
        return rsrv;
    }

    public static getReservationName(rsrv: S25Reservation) {
        return rsrv.itemName || "rsrv_" + rsrv.itemId;
    }

    public static async createReservation(startDt: Date, endDt: Date, profileId?: number): Promise<S25Reservation> {
        const item = await ReservationService.generateReservationIds(1);
        return {
            itemId: item?.idSet[0].rsrvId,
            state: "active",
            eventStart: startDt,
            eventEnd: endDt,
            locations: [],
            resources: [],
            profileId: profileId,
        } as S25Reservation;
    }

    public static async duplicateReservations(
        reservations: S25Reservation[],
        profileId: number,
    ): Promise<S25Reservation[]> {
        const items = await ReservationService.generateReservationIds(reservations.length);
        return reservations.map(
            (r, i) =>
                ({
                    ...r,
                    itemId: items?.idSet[1].rsrvId,
                    profileId: profileId,
                    profileName: "",
                }) as S25Reservation,
        );
    }

    public static addLocations(rsrv: S25Reservation, rsrvLocations: S25RmReservation[]) {
        let addedLocations = rsrvLocations;
        let notAdded: S25RmReservation[] = [];
        let requested: S25RmReservation[] = [];
        // TODO: check for and avoid duplicates maybe event check availability here?

        [].concat(rsrv.locations, addedLocations);
        return { added: addedLocations, notAdded: notAdded, requested: requested };
    }

    public static removeLocationsById(rsrv: S25Reservation, rmIds: number[]) {
        let removed = rmIds;
        let notRemoved: S25RmReservation[] = [];
        let requested: S25RmReservation[] = [];
        const filteredLocations = rsrv.locations.map((location: S25RsReservation) => ({
            ...location,
            requested: location.requested.filter((item: any) => !rmIds.includes(item.itemId)),
            draft: location.draft.filter((item: any) => !rmIds.includes(item.itemId)),
            reserved: location.reserved.filter((item: any) => !rmIds.includes(item.itemId)),
        }));
        rsrv.locations = filteredLocations;
        return rsrv;
    }

    public static removeResourceById(rsrv: S25Reservation, rsIds: number[]) {
        let removed = rsIds;
        const filteredResources = rsrv.resources.map((resource: S25RsReservation) => ({
            ...resource,
            requested: resource.requested.filter((item: any) => !rsIds.includes(item.itemId)),
            draft: resource.draft.filter((item: any) => !rsIds.includes(item.itemId)),
            reserved: resource.reserved.filter((item: any) => !rsIds.includes(item.itemId)),
        }));
        rsrv.resources = filteredResources;
        return rsrv;
    }
    public static removeLocations(rsrv: S25Reservation, rsrvLocations: S25Reservation[]) {
        let removed = rsrvLocations;
        let notRemoved: S25RmReservation[] = [];
        let requested: S25RmReservation[] = [];
        //TODO: not yet implemented
        return S25ReservationUtil.removeLocationsById(
            rsrv,
            rsrv.locations.map((l) => l.itemId),
        );
    }

    public static getLocations(rsrv: S25Reservation) {
        return rsrv.locations || [];
    }

    //     public static getName(spaceId:number, list:any, name: string) {
    //           let findName = S25Util.array.getByProp(list, name, spaceId);
    //           return findName;
    //      }

    public static setLocations(rsrv: S25RmReservation, locationsList: ExpandedInfoI) {
        let locations: S25RmReservation[] = [];
        [].concat(rsrv).forEach((s: ExpandedInfoI) => {
            // S25Util.all({
            //     // ols: OlsService.getOls(s.space_id, 4, "edit"),
            //     layouts: SpaceService.getSpaceIncludes(s.spaceId, ["layouts"]),
            // }).then((data) => {
            //     let findlayout: any = [];
            // if (data.layouts.layout) {
            //     findlayout = data.layouts.layout.filter(function (i: ExpandedInfoI) {
            //         return i.layout_id === s.layoutId;
            //     });
            // }
            let findName = locationsList.filter(function (i: ExpandedInfoI) {
                return i.spaceId === s.spaceId;
            });
            // 3 = denied or cancelled
            if (s.status !== 3) {
                //TODO: use a type for denied/cancelled
                locations.push({
                    itemId: s.spaceId,
                    itemName: findName[0]?.spaceName,
                    instructions: s.instructions || "",
                    isShare: s.share,
                    rating: s.rating,
                    attendance: s.attendance,
                    layout: {
                        itemId: parseInt(s.layoutId), //findlayout[0]?.layout_id, //layoutId
                        // itemName: findlayout[0]?.layout_name, //layoutName
                        // capacity: layout.selected_layout_capacity || '', //Capacity in the layout may be smaller than the max capacity for the room
                        roomCapacity: findName[0]?.maxCapacity || "", //Max capacity for the room
                    },
                    // locationLayout: data.layouts?.layout || [],
                } as S25RmReservation);
            }
            // });
        });
        return locations;
    }

    public static setResources(rsrv: S25RsReservation, resourcesList: ExpandedInfoI) {
        let resources: S25RsReservation[] = [];
        if (!resourcesList) return false;
        [].concat(rsrv).forEach((res: ExpandedInfoI) => {
            let findRes = resourcesList.filter(function (i: ExpandedInfoI) {
                return i.resourceId === res.resourceId;
            });
            // 3 = denied or cancelled
            if (res.status !== 3) {
                resources.push({
                    itemId: res.resourceId,
                    itemName: findRes[0].resourceName,
                    quantity: res.quantity,
                    instructions: res.instructions,
                    currentStockLevel: findRes[0]?.currentStockLevel,
                });
            }
        });
        return resources;
    }

    public static checkObjectsDatesAvailability(
        eventId: number,
        profileId: number,
        dates: [],
        locations: S25ObjectReservation[],
        resources: S25ObjectReservation[],
    ) {
        let promiseArr = [];
        let locationArr: any = []; //locations[0] || [];
        let resourceArr: any = []; //resources[0] || [];
        let locationsIds: any = [];

        if (locations.length > 0) {
            locationArr = [].concat(locations[0]?.draft, locations[0]?.requested, locations[0]?.reserved);
            locationsIds = S25Util.toItemIds(locationArr); // sapceId in array [1, 2.3...]
        }
        if (resources.length > 0)
            resourceArr = [].concat(resources[0]?.draft, resources[0]?.requested, resources[0]?.reserved);
        if (locationArr.length > 0)
            promiseArr.push(SpaceService.getSpaceDatesAvailability(locationsIds, dates, eventId, profileId));
        if (resourceArr.length > 0)
            promiseArr.push(ResourceService.getResourceDatesAvailability(resourceArr, dates, eventId, profileId));
        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                return resp;
            });
        }
    }

    public static async getObjectRsrvId(event: S25Event, rsrvId: number, objectId: number, itemTypeId: number) {
        let find;
        const reservation = await this.getReservationsRsrvId(event, rsrvId);
        if (reservation) {
            if (itemTypeId === 6) {
                const resourcesFlat = this.getObjectsFlat(reservation.resources[0]);
                find = resourcesFlat.find((r: S25RsReservation) => r.itemId === objectId);
            } else {
                const locationsFlat = this.getObjectsFlat(reservation.locations[0]);
                find = locationsFlat.find((l: S25RmReservation) => l.itemId === objectId);
            }
            return find;
        } else {
            return undefined;
        }
    }

    public static getReservationsRsrvId(event: any, rsrvId: number) {
        for (const profile of event.profile) {
            for (const rsrv of profile.reservations) {
                if ((rsrv.itemId as any) === rsrvId) {
                    return rsrv;
                }
            }
        }
        return undefined;
    }

    public static getObjectsFlat(rsrv: S25RmReservation | S25RsReservation): S25RmReservation[] | S25RsReservation[] {
        if (!rsrv) return [];
        let items = new Set();
        rsrv.draft?.forEach((r) => items.add(r));
        rsrv.reserved?.forEach((r) => items.add(r));
        rsrv.requested?.forEach((r) => items.add(r));
        return Array.from(items);
    }

    public static getReservationsStartEndDates(rsrvs: S25Reservation[]) {
        let dates = [];
        for (const r of rsrvs) {
            if (r.state === "active") {
                dates.push({
                    startDt:
                        S25Util.date.parse(r.setupStart) ||
                        S25Util.date.parse(r.preEventStart) ||
                        S25Util.date.parse(r.eventStart),
                    endDt:
                        S25Util.date.parse(r.takeDownEnd) ||
                        S25Util.date.parse(r.postEventEnd) ||
                        S25Util.date.parse(r.eventEnd),
                });
            }
        }
        return dates;
    }
}
