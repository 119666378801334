import { S25ReservationUtil } from "./s25.reservation";
import { SpaceService, S25WsSpace } from "../../../services/space.service";
import { S25ObjectReservation, S25RsReservation, S25RmReservation } from "../ReservationI";

export class comment {
    commentId: number;
    comment: string;
}

export class S25RmReservationUtil extends S25ReservationUtil {
    public static getInstructions(rsrv: S25RmReservation | S25RsReservation): string {
        if ("layout" in rsrv && rsrv.layout) {
            return rsrv.layout.defaultInstructions || "";
        } else {
            return rsrv.instructions || "";
        }
    }
    public static setInstructions(rsrv: S25ObjectReservation, instructions: string = ""): S25ObjectReservation {
        rsrv.instructions = instructions;
        return rsrv;
    }

    getIsShared(rsrv: S25RmReservation) {
        return !!rsrv.isShare;
    }

    /**
     * A reservation is shared IF it is flagged as shared (requires FLS perm) OR the booked space is always shared (no perms required)
     */
    setIsShared(rsrv: S25RmReservation, isShared: boolean) {
        //TODO: check FLS that they're allowed to share???
        rsrv.isShare = isShared;
        return rsrv;
    }

    public static getLocationsFlat(rsrv: S25RmReservation): S25RmReservation[] {
        if (!rsrv) return [];
        let locations = new Set();
        rsrv.draft?.forEach((r) => locations.add(r));
        rsrv.reserved?.forEach((r) => locations.add(r));
        rsrv.requested?.forEach((r) => locations.add(r));
        return Array.from(locations);
    }

    /****
     * Fetches the location details for all locations in the reservation and adds them to the location object
     * Adds all the layout options for each location to the location object
     * */
    public static async setLocationDetails(rmReservation: S25RmReservation) {
        const locationsFlat = S25RmReservationUtil.getLocationsFlat(rmReservation);
        const spaceIds = locationsFlat.map((l) => l.itemId);
        if (spaceIds.length === 0) return;
        const locDetails = await SpaceService.getSpacesIncludes(spaceIds, ["layouts"]);
        locationsFlat.forEach((loc) => {
            const spDetails = locDetails.find((details: S25WsSpace) => {
                return details.space_id === loc.itemId;
            });
            if (spDetails?.layout) {
                const usedLayout = spDetails.layout.find((l: S25WsSpace) => l.layout_id === loc.layout.itemId);
                loc.layout.itemName = usedLayout?.layout_name;
                loc.layout.capacity = usedLayout?.capacity || loc?.layout?.roomCapacity || "";
                loc.locationLayout = spDetails?.layout;
            }
        });
    }
}
